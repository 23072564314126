<template>
  <div class="v-stack h-stretch inputdatepicker-container">
    <div class="overlay" ref="overlay">
      <div class="card light border gap-3 pa-3">
        <div class="h-stack h-stretch">
          <button
            @click.prevent="today()"
            class="add"
            style="width: 100% !important"
          >
            Today
          </button>
        </div>
        <CalendarMonthPicker
          ref="picker"
          :date="internalDate"
          @datechange="setDate($event)"
          @dateselected="dismissPicker()"
          :highlightToday="false"
        ></CalendarMonthPicker>
        <div class="h-stack h-stretch">
          <button
            @click.prevent="dismissPicker()"
            class="submit"
            style="width: 100% !important"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
    <input v-model="dateInput" ref="input" placeholder="DD-MM-YYYY" />
    <div
      class="inputdatepicker-button inputdatepicker-link"
      @click="showPicker()"
    >
      <i class="far fa-calendar"></i>
    </div>
    <div
      class="inputdatepicker-message"
      v-bind:class="[
        valid ? 'inputdatepicker-success' : 'inputdatepicker-error',
      ]"
    >
      {{ valid ? "OK" : "Date not valid!" }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CalendarMonthPicker from "@/components/calendar/CalendarMonthPicker.vue";

export default {
  data() {
    return {
      dateInput: "",
      lastInputLength: 0,
      valid: false,
    };
  },
  props: {
    date: {
      type: String,
      default: moment().toISOString(),
    },
  },
  model: {
    prop: "date",
    event: "datechange",
  },
  components: {
    CalendarMonthPicker,
  },
  computed: {
    internalDate() {
      return moment(this.date);
    },
  },
  methods: {
    today() {
      this.$emit("datechange", moment().toISOString());
      this.$nextTick(() => {
        this.dismissPicker();
      });
    },
    showPicker() {
      this.refreshDate();
      this.$refs.overlay.classList.add("show");
      this.$refs.picker.resetEdit();
      if (!moment(this.date).isValid()) {
        this.$emit("datechange", moment().toISOString());
      }
    },
    dismissPicker() {
      this.$refs.overlay.classList.remove("show");
    },
    setDate(date) {
      this.$emit("datechange", date.toISOString());
    },
    refreshDate() {
      const date = this.internalDate;
      this.dateInput = `${date.format("DD")}-${date.format("MM")}-${date.format(
        "YYYY"
      )}`;
    },
  },
  watch: {
    dateInput() {
      const selectionIndex = this.$refs.input.selectionStart;
      let newInputValue = this.dateInput;

      let newIndex = selectionIndex;
      if (newIndex == 3 && newInputValue.length >= this.lastInputLength) {
        newIndex += 1;
      }
      if (newIndex == 6 && newInputValue.length >= this.lastInputLength) {
        newIndex += 1;
      }

      if (newInputValue.length > 10) {
        newInputValue =
          newInputValue.substring(0, newIndex) +
          newInputValue.substring(newIndex + 1);
      }

      newInputValue = newInputValue.replace(/[^0-9]+/g, "");
      newInputValue = newInputValue.substring(0, 8);

      if (newInputValue.length > 2) {
        newInputValue =
          newInputValue.slice(0, 2) + "-" + newInputValue.slice(2);
      }
      if (newInputValue.length > 5) {
        newInputValue =
          newInputValue.slice(0, 5) + "-" + newInputValue.slice(5);
      }
      this.$nextTick(() => {
        this.$refs.input.setSelectionRange(newIndex, newIndex);
        this.lastInputLength = newInputValue.length;

        this.dateInput = newInputValue;

        if (newInputValue.length < 10) {
          this.valid = false;
        } else {
          const day = newInputValue.substring(0, 2);
          const month = newInputValue.substring(3, 5);
          const year = newInputValue.substring(6, 10);
          const date = moment(`${year}-${month}-${day}T10:00:00`);
          this.valid = date.isValid();

          if (this.valid) {
            this.$emit("datechange", date.toISOString());
          }
        }
      });
    },
    date() {
      this.refreshDate();
    },
  },
  mounted() {
    this.refreshDate();
  },
};
</script>

<style lang="scss" scoped>
.inputdatepicker-container {
  position: relative;
}

.inputdatepicker-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

.inputdatepicker-message {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.inputdatepicker-link {
  user-select: none;
  cursor: pointer;
}

.inputdatepicker-error {
  color: red;
}

.inputdatepicker-success {
  color: rgb(41, 187, 28);
}
</style>
