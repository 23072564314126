<template>
  <div v-if="day" class="v-stack h-stretch gap-3">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to delete this day?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="remove()" class="edit">Delete</button>
        </div>
      </div>
    </div>
    <div class="card light">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left">Project</label>
          <div class="text-left">
            {{ day.project.client.name }} - {{ day.project.title }}
            {{ day.project.subtitle }}
          </div>
          <label class="text-left required">Date</label>
          <InputDatePicker v-model="day.startDate"></InputDatePicker>
          <div class="pane-horizontal gap-3">
            <label class="text-left required">From</label>
            <label class="text-left required">To</label>
          </div>
          <div class="pane-horizontal gap-3">
            <datetime
              type="time"
              :auto="true"
              v-model="day.startDate"
            ></datetime>
            <datetime type="time" :auto="true" v-model="day.endDate"></datetime>
          </div>
          <label class="text-left required">Address</label>
          <textarea v-model="day.address"></textarea>
          <label class="text-left required">Description</label>
          <textarea v-model="day.description"></textarea>
          <div class="h-stack h-space-between">
            <button @click="removeDialog()" class="edit">Delete</button>
            <button @click="submit()" class="submit">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  data() {
    return {
      day: null,
    };
  },
  components: {
    InputDatePicker,
  },
  methods: {
    ...mapActions([
      "getRecordingDay",
      "editRecordingDay",
      "removeRecordingDay",
    ]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    submit() {
      this.editRecordingDay(this.day)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    remove() {
      this.removeRecordingDay(this.id)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
  },
  mounted() {
    this.getRecordingDay(this.id)
      .then((day) => {
        this.day = day;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>